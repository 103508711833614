import React, { useEffect } from 'react';
import { userSignOut } from 'actions';
import { useDispatch } from 'react-redux';
import { apiRequest } from 'app/modules/shared/api/apiRequest';
import AppMetaAPI from 'app/modules/shared/api/AppMetaAPI';

const withCacheValidation = (WrappedComponent) => {
    const CacheValidationComponent = (props) => {
        const APP_DATA_NOT_FOUND = 'Not Found';
        const dispatch = useDispatch();
        const hasSavedAppMetaData = localStorage.getItem('appMetaData');

        useEffect(() => {
            apiRequest(AppMetaAPI.getCurrentAppMetaData)
                .then(({ data }) => {
                    const { minRequriedVerstion } = data;
                        if (minRequriedVerstion) {
                        let shouldForceRefresh = false;

                        if (
                            !hasSavedAppMetaData ||
                            hasSavedAppMetaData === APP_DATA_NOT_FOUND
                        ) {
                            shouldForceRefresh = true;
                        } else {
                            const savedAppMetaData =
                                JSON.parse(hasSavedAppMetaData);
                            shouldForceRefresh = semverGreaterThan(
                                minRequriedVerstion,
                                savedAppMetaData.currentAppVersion
                            );
                        }

                        if (shouldForceRefresh) {
                            clearCacheAndLogout(data);
                        } else {
                            localStorage.setItem(
                                'appMetaData',
                                JSON.stringify(data)
                            );
                        }
                    } else return;
                })
                .catch(() => {
                    console.log('Faild to fetch current app meta data');
                    localStorage.setItem('appMetaData', APP_DATA_NOT_FOUND);
                });
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        const semverGreaterThan = (versionA, versionB) => {
            const versionsA = versionA.split(/\./g);

            const versionsB = versionB.split(/\./g);
            while (versionsA.length || versionsB.length) {
                const a = Number(versionsA.shift());

                const b = Number(versionsB.shift());
                // eslint-disable-next-line no-continue
                if (a === b) continue;
                // eslint-disable-next-line no-restricted-globals
                return a > b || isNaN(b);
            }
            return false;
        };

        const clearCacheAndLogout = (meta) => {
            if (caches) {
                // Delete browser cache and logout the user
                caches
                    .keys()
                    .then((names) => {
                        for (const name of names) {
                            caches.delete(name);
                        }
                        deleteAllCookies();
                    })
                    .then(() => {
                        localStorage.clear();
                        dispatch(userSignOut());
                        localStorage.setItem(
                            'appMetaData',
                            JSON.stringify(meta)
                        );
                    })
                    .catch(() => {
                        console.log('Can not fetch caches keys to be cleared');
                    });
            } else {
                deleteAllCookies();
                localStorage.clear();
                dispatch(userSignOut());
                localStorage.setItem('appMetaData', JSON.stringify(meta));
            }
        };

        const deleteAllCookies = () => {
            document.cookie.split(';').forEach((cookie) => {
                const eqPos = cookie.indexOf('=');
                const name = eqPos > -1 ? cookie.substring(0, eqPos) : cookie;
                document.cookie =
                    name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
            });
        };

        return (
            <React.Fragment>
                <WrappedComponent {...props} />
            </React.Fragment>
        );
    };

    return CacheValidationComponent;
};

export default withCacheValidation;
