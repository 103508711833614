import Bowser from 'bowser';

export const getOSInfo = () => {
    const browser = Bowser.getParser(window.navigator.userAgent);
    return browser.getOS();
};

export const isIOSorMac = () => {
    const os = getOSInfo();
    return os.name === 'iOS' || os.name === 'macOS';
};
