import io from 'socket.io-client';
import { GET_SOCKET_URL } from '../../devtools/companyUrlSwitcher/utils';
import { isIOSorMac } from '../util/detectUserOS';

const baseURI = GET_SOCKET_URL();
const useSockets = isIOSorMac();

const userId =
    localStorage.getItem('user_id') &&
    JSON.parse(localStorage.getItem('user_id'))._id;

export const socket =
    useSockets || import.meta.env.VITE_ON_PREMISE === 'true'
        ? io(baseURI, {
              query: {
                  ...(userId && { userId }),
              },
          })
        : () => {};
