const NOTIFICATIONS_PERMISSION_KEY = 'notificationPermission';
const NOTIFICATIONS_SUBSCRIBER_TOKEN_KEY = 'notificationSubscriberToken';

const setPermissionStatus = (status) =>
    localStorage.setItem(NOTIFICATIONS_PERMISSION_KEY, status);

const removeNotificationSubscriberToken = () =>
    localStorage.removeItem(NOTIFICATIONS_SUBSCRIBER_TOKEN_KEY);

const handleGrantedPermission = () => {
    setPermissionStatus('granted');
};

const handleDeniedPermission = (notificationToken) => {
    setPermissionStatus('denied');
    if (notificationToken) {
        removeNotificationSubscriberToken();
    }
};

const checkPermissionTransition = (
    savedPermission,
    currentPermission,
    notificationToken
) => {
    if (savedPermission === 'granted' && currentPermission === 'denied') {
        handleDeniedPermission(notificationToken);
    } else if (
        savedPermission === 'denied' &&
        currentPermission === 'granted'
    ) {
        handleGrantedPermission();
    }
};

const requestNotificationPermission = (notificationToken) => {
    Notification.requestPermission()
        .then((permission) => {
            if (permission === 'granted') {
                handleGrantedPermission();
            } else {
                handleDeniedPermission(notificationToken);
            }
        })
        .catch((error) => {
            console.error('Failed to request notification permission:', error);
        });
};

export const getSavedPermissionStatus = () =>
    localStorage.getItem(NOTIFICATIONS_PERMISSION_KEY);

export const setNotificationSubscriberToken = (token) =>
    localStorage.setItem(NOTIFICATIONS_SUBSCRIBER_TOKEN_KEY, token);

export const getNotificationSubscriberToken = () =>
    localStorage.getItem(NOTIFICATIONS_SUBSCRIBER_TOKEN_KEY);

export const checkNotificationsPermission = () => {
    const notificationPermission = getSavedPermissionStatus();
    const currentPermission = Notification.permission;
    const notificationToken = getNotificationSubscriberToken();

    if (notificationPermission) {
        checkPermissionTransition(
            notificationPermission,
            currentPermission,
            notificationToken
        );
    } else {
        if (currentPermission === 'granted') {
            handleGrantedPermission();
        } else {
            requestNotificationPermission(notificationToken);
        }
    }
};
