import React, { Component } from 'react';

import { faSearch, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Button from '@material-ui/core/Button';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { NavLink, withRouter } from 'react-router-dom';
import Select, { components } from 'react-select';

import CustomScrollbars from 'util/CustomScrollbars';
import IntlMessages from 'util/IntlMessages';

import {
    setFactoryAQL,
    setFactoryAutomateBeats,
    setFactoryBatchGrouping,
    setFactoryCutting,
    setFactoryIdleTime,
    setFactoryTimezone,
} from '../../../../../reducers/Settings';
import {
    GET_COMPANY,
    GET_LARAVEL_URL,
} from '../../../devtools/companyUrlSwitcher/utils';
import getTranslation from '../../util/Language';
import LogoutDialog from '../LogoutDialog/LogoutDialog';

import sharedStyles from '../../styles/theme.js';
import styles from './SideNavContent.module.scss';

class SidenavContent extends Component {
    constructor(props) {
        super(props);

        const company =
            import.meta.env.MODE === 'development' ||
            import.meta.env.VITE_ON_PREMISE === 'true'
                ? GET_COMPANY()
                : window.location.host.split('.')[0];
        const userId = JSON.parse(localStorage.getItem('user_id'));

        this.externalUrl =
            userId &&
            `http://${company}.${GET_LARAVEL_URL()}/${userId.factory}`;
        this.params =
            userId &&
            `?cross=1&name=${userId.first_name}&email=${userId.email}&page=`;

        this.factory =
            localStorage.getItem('user_id') &&
            JSON.parse(localStorage.getItem('user_id')).factory;
    }
    state = {
        openLogoutDialog: false,
        activeLinkTitle: null,
    };

    static getDerivedStateFromProps(nextProps) {
        const { history } = nextProps;
        const pathname = `${history.location.pathname}`; // get current path

        const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
        try {
            const activeNav = this?.closest(activeLi, 'ul'); // select closest ul
            if (activeNav) {
                if (activeNav.classList?.contains('sub-menu')) {
                    this.closest(activeNav, 'li').classList.add('open');
                } else {
                    this.closest(activeLi, 'li').classList.add('open');
                }
                this.setState({
                    activeLinkTitle: activeLi.text,
                });
            }
        } catch (error) {
            console.log(error);
        }
        return null;
    }

    componentDidMount() {
        this.setState({
            items: this.flattenAllItems().map((item) => ({
                value: item.linkTo,
                label: getTranslation(item.name),
            })),
        });

        const { history } = this.props;
        const pathname = `${history.location.pathname}`; // get current path

        const menuLi = [...document.querySelectorAll('.nav-menu li')];
        menuLi.forEach((item) => {
            const hasUlChild = item.querySelector('ul');
            item.onclick = (e) => {
                e.stopPropagation();
                if (hasUlChild) {
                    item.classList.toggle('open');
                } else {
                    const url = item?.querySelector('a')?.getAttribute('href');
                    if (url?.startsWith('/')) {
                        const isFactoryItem =
                            item?.classList.contains('factoryItem');

                        if (isFactoryItem) {
                            const user = JSON.parse(this.props.authUser);
                            const factoryNumber = item
                                ?.querySelector('a')
                                ?.getAttribute('data-factoryNumber');
                            const factoryData = JSON.parse(
                                item
                                    ?.querySelector('a')
                                    ?.getAttribute('data-factoryData'),
                            );
                            user.factory = factoryNumber;
                            user.factoryData = factoryData;
                            localStorage.setItem(
                                'user_id',
                                JSON.stringify(user),
                            );
                            this.props.setFactoryIdleTime(
                                factoryData?.idleTime,
                            );
                            this.props.setFactoryBatchGrouping(
                                factoryData?.batchGrouping,
                            );
                            this.props.setFactoryCutting(
                                factoryData?.isCuttingEnabled,
                            );
                            this.props.setFactoryAutomateBeats(
                                factoryData?.isAutomateBeats,
                            );
                            this.props.setFactoryTimezone(
                                factoryData?.timeZone,
                            );
                            this.props.setFactoryAQL(factoryData);
                        } else if (!e.ctrlKey) {
                            e.preventDefault();
                            history.push(url);
                        }
                    }
                    item.classList.toggle('active');
                    item.classList.toggle('open');
                }
            };
        });

        const activeLi = document.querySelector('a[href="' + pathname + '"]'); // select current a element
        try {
            if (this) {
                this.getParents(activeLi).forEach((item) => {
                    item.classList.add('open');
                });
                this.setState({
                    activeLinkTitle: activeLi.text,
                });
            }
        } catch (error) {
            console.warn(error);
        }
    }

    getParents = (elem) => {
        const parents = [];
        while (
            elem.parentNode &&
            elem.parentNode.nodeName.toLowerCase() !== 'body'
        ) {
            elem = elem.parentNode;
            if (elem.classList.contains('menu')) parents.push(elem);
        }
        return parents;
    };

    flattenAllItems = () => {
        const items = [];
        this.props.data.forEach((item) => {
            if (item.type === 'item') {
                items.push(item);
            } else if (item.type.includes('Section')) {
                if (item.id === 'factoryItem') return;
                items.push(...item.children);
            } else {
                item.children.forEach((child) => {
                    if (child.type === 'item') {
                        items.push(child);
                    } else {
                        items.push(...child.children);
                    }
                });
            }
        });
        return items;
    };

    closest = (el, selector) => {
        try {
            let matchesFn;
            // find vendor prefix
            [
                'matches',
                'webkitMatchesSelector',
                'mozMatchesSelector',
                'msMatchesSelector',
                'oMatchesSelector',
            ].some(function (fn) {
                if (typeof document.body[fn] === 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {
            console.log(e);
        }

        return null;
    };

    openLogoutDialog = () => {
        this.setState({
            openLogoutDialog: true,
        });
    };

    closeLogoutDialog = () => {
        this.setState({
            openLogoutDialog: false,
        });
    };

    getMenuItem = (dataItem) => {
        return (
            <>
                {dataItem.type === 'factoryItem' && (
                    <li className='factoryItem'>
                        <a
                            href={dataItem.linkTo}
                            data-factoryNumber={dataItem.factoryNumber}
                            data-factoryData={JSON.stringify(
                                dataItem.factoryData,
                            )}
                            className='prepend-icon'
                        >
                            <FontAwesomeIcon
                                icon={dataItem.icon}
                                size='lg'
                                className={` ${styles.faIcon}`}
                            />
                            <span className='nav-text px-2'>
                                <IntlMessages id={dataItem.name} />
                            </span>
                        </a>
                    </li>
                )}

                {dataItem.type !== 'factoryItem' && (
                    <li key={dataItem.name}>
                        {dataItem.linkTo.startsWith('/') ? (
                            <NavLink
                                className='prepend-icon'
                                to={
                                    dataItem.linkTo.includes('factories')
                                        ? dataItem.linkTo
                                        : `/${this.factory}${dataItem.linkTo}`
                                }
                            >
                                <FontAwesomeIcon
                                    icon={dataItem.icon}
                                    size='lg'
                                    className={styles.faIcon}
                                />
                                <span className='nav-text'>
                                    <IntlMessages id={dataItem.name} />
                                </span>
                            </NavLink>
                        ) : (
                            <a
                                href={`${
                                    dataItem.linkTo !== 'users'
                                        ? this.externalUrl
                                        : this.externalUrl.substring(
                                              0,
                                              this.externalUrl.length - 2,
                                          )
                                }/${dataItem.linkTo}${this.params}${
                                    dataItem.linkTo
                                }`}
                            >
                                <FontAwesomeIcon
                                    icon={dataItem.icon}
                                    size='lg'
                                    className={styles.faIcon}
                                />
                                <span className='nav-text'>
                                    <IntlMessages id={dataItem.name} />
                                </span>
                            </a>
                        )}
                    </li>
                )}
            </>
        );
    };

    getCategory = (category) => {
        return (
            <li key={category.name} className='ui_tooltip menu'>
                <Button className='void'>
                    <FontAwesomeIcon
                        icon={category.icon}
                        size='lg'
                        className={styles.faIcon}
                    />
                    <span
                        className='nav-text'
                        style={{
                            textDecoration:
                                category.id === 'factoryItem'
                                    ? 'underline'
                                    : '',
                        }}
                    >
                        <IntlMessages id={category.name} />
                    </span>
                </Button>
                <ul className='sub-menu'>
                    {category.children.map((child, index) => {
                        return (
                            <React.Fragment key={child.name || index}>
                                {this.processMenuItem(child)}
                            </React.Fragment>
                        );
                    })}
                </ul>
            </li>
        );
    };

    processMenuItem = (dataItem) =>
        dataItem.children && dataItem.type.includes('collapse')
            ? this.getCategory(dataItem)
            : this.getMenuItem(dataItem);

    navigateToItem = (event) => {
        if (event?.value.startsWith('/')) {
            if (event.value === '/factories') {
                this.props.history.push(`${event.value}`);
            } else this.props.history.push(`/${this.factory}${event.value}`);
        } else {
            window.location.href = `${
                event.value !== 'users'
                    ? this.externalUrl
                    : this.externalUrl.substring(0, this.externalUrl.length - 2)
            }/${event.value}${this.params}${event.value}`;
        }
    };

    DropdownIndicator = (props) => {
        return (
            <components.DropdownIndicator {...props}>
                <FontAwesomeIcon
                    icon={faSearch}
                    color={sharedStyles.goldenColor}
                />
            </components.DropdownIndicator>
        );
    };

    ValueContainer = ({ children, ...props }) => (
        <div className='ValueContainer w-75'>
            <components.ValueContainer {...props}>
                {children}
            </components.ValueContainer>
        </div>
    );

    customStyles = {
        control: (provided) => ({
            ...provided,
            borderColor: sharedStyles.goldenColor,
            background: sharedStyles.primaryColor,
        }),
        placeholder: (provided) => ({
            ...provided,
            color: sharedStyles.goldenColor,
        }),
        input: (provided) => ({
            ...provided,
            color: sharedStyles.goldenColor,
        }),
    };

    render() {
        const { data, showLogoutMenu } = this.props;
        const { openLogoutDialog, activeLinkTitle } = this.state;

        return (
            <>
                <Helmet>
                    <title>{activeLinkTitle}</title>
                </Helmet>
                <CustomScrollbars
                    className=' scrollbar'
                    renderThumbVertical={(props, style) => (
                        <div
                            {...props}
                            className='thumb-vertical'
                            style={{
                                ...style,
                                backgroundColor: 'rgba(255,255,255,.4)',
                                borderRadius: '8px',
                                width: '8px',
                            }}
                        />
                    )}
                    renderTrackVertical={(props, style) => (
                        <div
                            {...props}
                            className='track-vertical'
                            style={{
                                ...style,
                                width: '12px',
                                height: '100%',
                                left: '5px',
                                position: 'absolute',
                            }}
                        />
                    )}
                >
                    <div className='px-4 mb-2 w-100'>
                        <Select
                            styles={this.customStyles}
                            options={this.state.items}
                            onChange={(event) => this.navigateToItem(event)}
                            isClearable={true}
                            placeholder={getTranslation('shared.search')}
                            components={{
                                DropdownIndicator: this.DropdownIndicator,
                                ValueContainer: this.ValueContainer,
                            }}
                        />
                    </div>
                    <ul className='nav-menu'>
                        {data.map((dataItem, index) => (
                            <React.Fragment key={dataItem.name || index}>
                                {this.processMenuItem(dataItem)}
                            </React.Fragment>
                        ))}

                        {showLogoutMenu && openLogoutDialog && (
                            <LogoutDialog
                                isOpen={openLogoutDialog}
                                closeDialog={this.closeLogoutDialog}
                            />
                        )}
                        {showLogoutMenu && (
                            <li>
                                <Button
                                    className='px-4'
                                    onClick={this.openLogoutDialog}
                                >
                                    <FontAwesomeIcon
                                        icon={faSignOutAlt}
                                        size='lg'
                                        className={styles.faIcon}
                                    />
                                    <span className='nav-text'>
                                        <IntlMessages id='popup.logout' />
                                    </span>
                                </Button>
                            </li>
                        )}
                    </ul>
                </CustomScrollbars>
            </>
        );
    }
}
const mapStateToProps = ({ settings, auth: { authUser } }) => ({
    authUser,
    settings,
});

export default connect(mapStateToProps, {
    setFactoryIdleTime,
    setFactoryBatchGrouping,
    setFactoryAQL,
    setFactoryCutting,
    setFactoryAutomateBeats,
    setFactoryTimezone,
})(withRouter(SidenavContent));
