import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import { getNotifications } from 'actions/Notifications';
import React from 'react';
import { connect } from 'react-redux';
import CustomScrollbars from 'util/CustomScrollbars';
import IntlMessages from 'util/IntlMessages';
import styles from '../../../../styles/theme.js';
import NotificationItem from './NotificationItem';
import { genId } from 'app/modules/shared/util/genId';

const AppNotification = ({
    notifications,
    deleteNotification,
    clickNotification,
    numberOfNotifications,
    getNotifications,
    slice,
}) => {
    return (
        <CustomScrollbars
            className='messages-list scrollbar'
            style={{ height: 280 }}
        >
            {notifications.length > 0 ? (
                <List>
                    {notifications.map((notification, index) => (
                        <React.Fragment key={genId() + index}>
                            <NotificationItem
                                bgColor={styles.lightColor}
                                notification={notification}
                                onDeleteNotification={deleteNotification}
                                onClickNotification={clickNotification}
                            />
                            {index !== notifications.length - 1 && <Divider />}
                        </React.Fragment>
                    ))}
                    {notifications.length !== numberOfNotifications && (
                        <div className='d-flex justify-content-center align-items-center mt-2'>
                            <Button onClick={() => getNotifications(slice)}>
                                <IntlMessages id='notifications.loadMore' />
                            </Button>
                        </div>
                    )}
                </List>
            ) : (
                <div className='d-flex align-items-center h-100 justify-content-center'>
                    <IntlMessages id='appNotification.message' />
                </div>
            )}
        </CustomScrollbars>
    );
};

const mapStateToProps = ({ auth: { authUser }, notifications: { slice } }) => ({
    authUser,
    slice,
});

export default connect(mapStateToProps, { getNotifications })(AppNotification);
