import {
    setImpersonationData,
    setSocketId,
    setToast,
    showAuthMessage,
    userSignInSuccess,
    userSignOutSuccess,
} from 'actions/Auth';
import { switchLanguage } from 'actions/Setting';
import {
    HOLD_TOAST,
    IMPERSONATE_USER,
    SET_SOCKET_ID,
    SET_USER_PERMISSION,
    SIGNIN_USER,
    SIGNOUT_USER,
} from 'constants/ActionTypes';
import {
    all,
    call,
    delay,
    fork,
    put,
    select,
    takeEvery,
} from 'redux-saga/effects';

import React from 'react';

import languageData from '../app/modules/shared/components/LanguageSwitcher/data';

import showNotificationMessage, {
    getErrorMessage,
    getErrorMessageByStatus,
} from '../app/modules/shared/util/NotificationMessage';
import getUserPermissions from '../app/modules/shared/util/Permissions';
import { openLink } from '../app/modules/shared/util/openLink';
import { redirectToLandingPage } from '../app/modules/shared/util/redirectToLandingPage';

import { DynamicDashboardsAPI } from 'app/modules/shared/api/DynamicDashboardsAPI';
import { errorHandlerNewPattern } from 'app/modules/shared/util/globalErrorHandler';
import { setMetabaseData } from 'reducers/Routes';
import IntlMessages from 'util/IntlMessages';
import { GET_COMPANY } from '../app/modules/devtools/companyUrlSwitcher/utils';
import AuthAPI from '../app/modules/shared/api/AuthAPI';
import {
    currentLocale,
    setCompanyAQL,
    setCompanyBatchGrouping,
    setCompanyCutting,
    setCompanyIdleTime,
    setFactoryAQL,
    setFactoryAutomateBeats,
    setFactoryBatchGrouping,
    setFactoryCutting,
    setFactoryIdleTime,
    setFactoryTimezone,
} from '../reducers/Settings';

import { RoutesAPI } from 'app/modules/Router/utils/RoutesAPI';
import getNotAuthTranslation from 'app/modules/shared/util/getNotAuthTranslation';
import SectionTagsAPI from 'app/modules/Production/SectionTags/SectionTagsAPI';
import { setAllSectionsTags } from 'reducers/SectionTags';

const AuthApi = new AuthAPI();

const signInUserWithEmailPasswordRequest = (email, password) =>
    AuthApi.signIn(email, password);

const cardLogin = (companySlug, rfid) => AuthApi.signInCard(companySlug, rfid);

const getOneFactoryRequest = (token, company_slug) =>
    AuthApi.getOneFactory(token, company_slug);
const getCountriesRequest = (token) => AuthApi.getCountries(token);

const impersonateUserRequest = (impersonatedId) =>
    AuthApi.impersonateUser(impersonatedId);

const getMetabaseDashboards = (company_slug, factory_id) =>
    DynamicDashboardsAPI.getUserDashboards(company_slug, factory_id);

const getSideMenuCategories = () => RoutesAPI.getSideMenuCategories();

const getFactorySectionTags = (company_slug, factory_id) =>
    SectionTagsAPI.getAll(company_slug, factory_id);

function* loginUser({ payload }) {
    let { email, password, signInResponse, from, rfid } = payload;

    const locale = yield select(currentLocale);

    try {
        let response;

        if (signInResponse) {
            response = signInResponse;
        }
        if (rfid) {
            let cardLoginCompany;

            const domainName = window.location.host;
            const domainNameParams = domainName.split('.');

            if (import.meta.env.MODE === 'development' || import.meta.env.VITE_ON_PREMISE === 'true') {
                cardLoginCompany = GET_COMPANY();
            } else {
                cardLoginCompany = domainNameParams[0];
            }

            response = yield call(cardLogin, cardLoginCompany, rfid);

            showNotificationMessage(
                'success',
                <IntlMessages id='signin.switchUser' />,
                5000
            );
        }
        if (email && password) {
            response = yield call(
                signInUserWithEmailPasswordRequest,
                email,
                password
            );
        }

        const responsePayload = response.data;

        if (responsePayload.success === 'true') {
            const signInUser = responsePayload.user;

            // Window.location.host is subdomain.domain.com
            const domainName = window.location.host;
            const domainNameParams = domainName.split('.');
            if (import.meta.env.MODE === 'development' || import.meta.env.VITE_ON_PREMISE === 'true') {
                signInUser.company = GET_COMPANY();
            } else {
                signInUser.company = domainNameParams[0];
            }
            signInUser.token = responsePayload.token;
            signInUser.permissions = getUserPermissions(true, signInUser);

            const factoryResponse = yield call(
                getOneFactoryRequest,
                signInUser.token,
                signInUser.company
            );

            const factoryPayload = factoryResponse.data;

            const countriesResponse = yield call(
                getCountriesRequest,
                signInUser.token
            );

            const countryPayload = countriesResponse?.data;

            if (countryPayload.status) {
                signInUser.countries = countryPayload.countries;
                localStorage.setItem('countries', JSON.stringify(signInUser));
            }

            if (factoryPayload.status) {
                signInUser.factory = Number(
                    factoryPayload.factories.at(0).factory_company_id
                );
                signInUser.factoryData = factoryPayload.factories.at(0);
                signInUser.factories = factoryPayload.factories;
                signInUser.companyData = factoryPayload.company;

                yield put(setFactoryIdleTime(signInUser.factoryData.idleTime));
                yield put(setCompanyIdleTime(signInUser.companyData.idleTime));

                yield put(
                    setFactoryBatchGrouping(
                        signInUser.factoryData.batchGrouping
                    )
                );
                yield put(
                    setCompanyBatchGrouping(
                        signInUser.companyData.batchGrouping
                    )
                );

                const isAQLFactoryEnabled = signInUser.factoryData.isAQLEnabled;
                yield put(
                    setFactoryAQL({
                        isAQLEnabled: isAQLFactoryEnabled,
                        ...(isAQLFactoryEnabled
                            ? {
                                  generalInspectionLevel:
                                      signInUser.factoryData
                                          .generalInspectionLevel,
                                  majorDefectsAQL:
                                      signInUser.factoryData.majorDefectsAQL,
                                  minorDefectsAQL:
                                      signInUser.factoryData.minorDefectsAQL,
                              }
                            : {}),
                    })
                );
                yield put(setCompanyAQL(signInUser.companyData.isAQLEnabled));
                //yield put(setCompanyAQL(true));

                yield put(
                    setFactoryCutting(signInUser.factoryData.isCuttingEnabled)
                );
                yield put(
                    setCompanyCutting(signInUser.companyData.isCuttingEnabled)
                );
                yield put(
                    setFactoryAutomateBeats(
                        signInUser.factoryData.isAutomateBeats
                    )
                );
                yield put(setFactoryTimezone(signInUser.factoryData.timeZone));

                signInUser.factoryHasSections =
                    factoryPayload.company.has_sections;
                localStorage.setItem('user_id', JSON.stringify(signInUser));

                const userLocale = languageData.find(
                    (language) => language.locale === signInUser.lang
                );
                localStorage.setItem('lang', JSON.stringify(userLocale));
                yield put(switchLanguage(userLocale));

                yield put(userSignInSuccess(JSON.stringify(signInUser)));

                const { data } = yield call(
                    getMetabaseDashboards,
                    signInUser.company,
                    signInUser.factory
                );
                yield put(setMetabaseData(data));

                const { data: sideMenuCategories } = yield call(
                    getSideMenuCategories
                );

                localStorage.setItem(
                    'sideMenuCategories',
                    JSON.stringify(sideMenuCategories.data)
                );

                const {
                    data: { tags },
                } = yield call(
                    getFactorySectionTags,
                    signInUser.company,
                    signInUser.factory
                );

                yield put(setAllSectionsTags(tags));

                if (from === '/404' || from === '/403' || from === 'homepage') {
                    redirectToLandingPage(signInUser);
                    return;
                } else {
                    from = handleStationsLogin(from, signInUser);
                    openLink(from, { ctrlKey: false });
                }
            } else {
                const errorMessageKey = getErrorMessage(factoryPayload.message);
                yield put(
                    showAuthMessage(
                        getNotAuthTranslation(locale.locale, errorMessageKey)
                    )
                );
            }
        } else {
            const errorMessageKey = getErrorMessage(responsePayload.message);
            yield put(
                showAuthMessage(
                    getNotAuthTranslation(locale.locale, errorMessageKey)
                )
            );
        }
    } catch (error) {
        const status = (error.response && error.response.status) || '';

        if (status === 400 || status === 401)
            return errorHandlerNewPattern(error);

        const errorMessageKey = getErrorMessageByStatus(status);

        yield put(
            showAuthMessage(
                getNotAuthTranslation(locale.locale, errorMessageKey)
            )
        );
    }
}

export const handleStationsLogin = (from, signInUser) => {
    const fromArray = from.split('/');
    if (fromArray.length > 3 && fromArray[2].includes('stations')) {
        const stationNumber = fromArray[3];
        const hasAccess =
            signInUser.scope.garment ||
            (signInUser.stations &&
                signInUser.stations.find(
                    (s) => s.control_unit_factory_id === parseInt(stationNumber)
                ));
        if (!hasAccess) from = `/${fromArray[1]}/stations`;
    }
    return from;
};

function* updateUserPermissions({ payload }) {
    const newUser = JSON.stringify({
        ...JSON.parse(localStorage.getItem('user_id')),
        permissions: payload.permissions,
        scope: payload.scope,
        stations: payload.stations,
        roles:
            JSON.parse(localStorage.getItem('user_id')).roles &&
            JSON.parse(localStorage.getItem('user_id')).roles.map((role, idx) =>
                idx === 0 ? { ...role, home_page: payload.homepage } : role
            ),
        ...(payload.token && { token: payload.token }),
    });

    localStorage.setItem('user_id', newUser);

    yield put(userSignInSuccess(newUser));

    openLink('/reload', { ctrlKey: false }, { from: payload.from });
}

function* impersonate({ payload }) {
    const response = yield call(impersonateUserRequest, payload);
    const user = response.data.data.user;

    const factoryResponse = yield call(
        getOneFactoryRequest,
        response.data.data.token,
        user.company.slug
    );
    const factory = factoryResponse.data.factories.at(0).factory_company_id;

    const permissions = getUserPermissions(true, user);
    let scope = 'section';
    if (user.scope.garment) scope = 'garment';
    else if (user.scope.company.length > 0) scope = 'company';
    else if (user.scope.factory.length > 0) scope = 'factory';
    else if (user.scope.production_line.length > 0) scope = 'production_line';

    const userNewData = {
        token: response.data.data.token,
        roles: user.roles,
        permissions: permissions,
        lang: user.lang,
        first_name: user.first_name,
        last_name: user.last_name,
        email: user.email,
        scope: scope,
        allow_notifications: user.allow_notifications,
        tours_finished: user.tours_finished || [],
        password: user.password,
        notificationsConfig: user.notificationsConfig,
        factory: factory,
        company: user.company.slug,
        stations: user.stations,
    };

    const oldUserData = JSON.parse(localStorage.getItem('user_id')); //garment user
    const userNewDataLocalStorag = JSON.stringify({
        ...oldUserData,
        ...userNewData,
    });
    localStorage.setItem('user_id', userNewDataLocalStorag);
    yield put(setImpersonationData(userNewData));

    redirectToLandingPage(userNewData);
}

function* updateUserPermissionsListener() {
    yield takeEvery(SET_USER_PERMISSION, updateUserPermissions);
}

function* holdingToast() {
    yield put(setToast(false));

    yield delay(4000);

    yield put(setToast(true));
}

function* holdToastListener() {
    yield takeEvery(HOLD_TOAST, holdingToast);
}

function* setSocket({ payload }) {
    yield setSocketId(payload);
}

function* setSocketListener() {
    yield takeEvery(SET_SOCKET_ID, setSocket);
}

function* signOut() {
    localStorage.removeItem('user_id');
    // Remove 'notificationSubscriberToken' from localStorage to make sure that it will be updated in the Database
    localStorage.removeItem('notificationSubscriberToken');

    yield put(userSignOutSuccess());
}

export function* signInUser() {
    yield takeEvery(SIGNIN_USER, loginUser);
}

export function* signOutUser() {
    yield takeEvery(SIGNOUT_USER, signOut);
}

export function* impersonateUser() {
    yield takeEvery(IMPERSONATE_USER, impersonate);
}

export default function* rootSaga() {
    yield all([
        fork(signInUser),
        fork(signOutUser),
        fork(setSocketListener),
        fork(updateUserPermissionsListener),
        fork(holdToastListener),
        fork(impersonateUser),
    ]);
}
